import React, {
  useState, useEffect, useContext,
} from 'react';
import NewCarbonPage from './components/NewCarbonPage';
import DeniedCarbonPage from './components/DeniedCarbonPage';
import ApprovedCarbonPage from './components/ApprovedCarbonPage';
import {
  getInfoNewZonesCarbonBodyRequest,
  handleResponseStatus,
  sendRequest,
} from '../../utils/api-request';
import getConfig from '../../config';
import { appStore } from '../../state/app';
import CustomPopup from '../../generic/CustomPopup';
import Button from '../../generic/Button';

const CarbonProjects = ({ tab }) => {
  const [filterQuery, setFilterQuery] = useState();
  const { indexerApi } = getConfig();
  const { update } = useContext(appStore);
  const [showError, setShowError] = useState(false);
  const setInfo = (data) => {
    const info = data.hits?.total?.value;
    update('counterZones', info);
  };
  const getInfoAboutNewZones = async () => {
    const { statusCode, result } = await sendRequest(indexerApi, 'POST', JSON.stringify(getInfoNewZonesCarbonBodyRequest));
    handleResponseStatus(
      statusCode,
      () => setInfo(result),
      () => setShowError(true),
    );
  };
  useEffect(() => {
    getInfoAboutNewZones();
  }, []); return (
    <div className="carbon">
      {tab === 'new' && <NewCarbonPage activeTab={tab} filterQuery={filterQuery} setFilterQuery={setFilterQuery} />}
      {tab === 'approve' && <ApprovedCarbonPage activeTab={tab} filterQuery={filterQuery} setFilterQuery={setFilterQuery} />}
      {tab === 'denied' && <DeniedCarbonPage activeTab={tab} filterQuery={filterQuery} setFilterQuery={setFilterQuery} />}
      {showError && (
        <CustomPopup
          widthParam="wrapper-popup"
          setShowPopUp={() => {
            setShowError(false);
            window.reload();
          }}
        >
          <div className="leakage__popup">
            <p className="leakage__popup-title">
              Oops!
            </p>
            <p className="leakage__popup-desc">
              Something goes wrong!
            </p>
            <Button
              className="leakage__popup-btn"
              onClick={() => {
                setShowError(false);
                window.reload();
              }}
            >
              <span>Ok</span>
            </Button>
          </div>
        </CustomPopup>
      )}
    </div>
  );
};

export default CarbonProjects;
