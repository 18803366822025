import React from 'react';

const Footer = ({ customClass }) => (
  <footer className={`footer ${customClass} `}>
    &#169; Open Forest Protocol,
    {' '}
    {new Date().getFullYear()}
  </footer>
);

export default Footer;
