import React, {
  useState,
  useEffect,
  memo,
  useCallback,
} from 'react';
import debounce from 'lodash.debounce';
import { CSVLink } from 'react-csv';
import Button from '../../generic/Button';
import CustomInput from '../../generic/CustomInput';
import useCarbonList from '../../hooks/useCarbonList';
import { makeCsvForCarbonProjects } from '../../utils/helpers';

const Filters = ({ setFilterQuery, activeSort, typeOfCarbon }) => {
  const [projectValue, setProjectValue] = useState('');
  const [organisationValue, setOrganisationValue] = useState('');
  const { projectsList, isLoading } = useCarbonList(null, activeSort, typeOfCarbon, true);
  const resetFilter = () => {
    setProjectValue('');
    setOrganisationValue('');
  };
  const setFilterWithDelay = useCallback(debounce((project, organisation) => {
    if (!project && !organisation) {
      setFilterQuery();
      return;
    }

    const mustQuery = [];
    if (project) {
      mustQuery.push({
        match_phrase_prefix: {
          name: {
            query: project,
          },
        },
      });
    }
    if (organisation) {
      mustQuery.push({
        match_phrase_prefix: {
          developer: {
            query: organisation,
          },
        },
      });
    }
    setFilterQuery(mustQuery);
  }, 500), []);
  const data = makeCsvForCarbonProjects(projectsList, typeOfCarbon);

  useEffect(() => {
    setFilterWithDelay(projectValue, organisationValue);
  }, [
    projectValue,
    organisationValue,
  ]);

  return (
    <div className="filters">
      <div className="filters__items-wrapper">

        <div className="filters__item-wrapper">
          <CustomInput
            label="Name"
            placeholder="Name"
            onChange={(e) => setProjectValue(e.target.value)}
            viewType="name"
            value={projectValue}
          />
        </div>
        <div className="filters__item-wrapper">
          <CustomInput
            label="Organisation"
            placeholder="Organisation"
            onChange={(e) => setOrganisationValue(e.target.value)}
            viewType="organisation"
            value={organisationValue}

          />
        </div>
      </div>
      <div className="filters__items-wrapper last">
        <CSVLink
          data={
              data
            }
          filename={`CarbonProject-${typeOfCarbon}`}
          className={`filters__csv btn ${isLoading ? 'download' : ''}`}
        >
          {isLoading ? 'Loading...' : 'Download CSV'}

        </CSVLink>
        <div className="filters__btn-wrapper">
          <Button
            onClick={() => resetFilter()}
            className="reset-filters"
            disabled={!(projectValue || organisationValue)}
          >
            Reset Filters
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(Filters);
