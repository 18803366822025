import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Sorting from '../../../../components/Sorting';
import CarbonCard from '../../../../components/CarbonCard';
import Loader from '../../../../components/Loader';
import useCarbonList from '../../../../hooks/useCarbonList';
import Filters from '../../../../components/Filters/Filters';

const ApprovedCarbonPage = ({ activeTab, filterQuery, setFilterQuery }) => {
  const [activeSort, setActiveSort] = useState({ key: 'nextValidation', value: 'desc' });
  const { projectsList, isLoading, loadMore } = useCarbonList(filterQuery, activeSort, 'approve', false);
  const { ref } = useInView({
    root: null,
    threshold: 0,
    onChange: (inView) => {
      if (inView) {
        loadMore();
      }
    },
  });
  return (
    <div className="carbon__wrapper">
      <Filters setFilterQuery={setFilterQuery} activeSort={activeSort} typeOfCarbon={activeTab} />

      <Sorting
        viewType="approvePage"
        activeSort={activeSort}
        setActiveSort={setActiveSort}
      />
      {!isLoading && !!projectsList.length && projectsList.map((project, index, array) => (
        <div className="carbon__list" key={`approve${project?.project_id}`}>
          <CarbonCard viewType="approve" cardRef={ref} data={project} sort={activeSort} isLast={array.length - 1 === index} />
        </div>
      ))}
      {isLoading && (
        <div className="carbon__loader">
          <Loader />
        </div>
      )}
      {!projectsList.length && !isLoading && <p className="carbon__phases">No projects</p>}

    </div>
  );
};

export default ApprovedCarbonPage;
