export const projectTypes = [
  {
    value: 'reforestation',
    title: 'Afforestation / Reforestation',
  },
  {
    value: 'conservation',
    title: 'Forest Conservation',
  },
  {
    value: 'agroforestry',
    title: 'Agroforestry',
  },
  {
    value: 'regeneration',
    title: 'Assisted Natural Regeneration',
  },
  {
    value: 'management',
    title: 'Improved Forest Management',
  },
];

export const headersCSV = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'developer',
    label: 'Organization',
  },
  {
    key: 'type',
    label: 'Project Type',
  },
  {
    key: 'square',
    label: 'Surface',
  },
  {
    key: 'startTimeProject',
    label: 'Start Date',
  },
  {
    key: 'region',
    label: 'Country',
  },
];

export const navigationTabs = {
  ApproveOrganizations: 'Approve Organizations',
  ApproveCarbonProjects: 'Approve Carbon Project',
  RegisterVal: 'Register Validators',
  Projects: 'Protocol Info',
  Permissions: 'Permissions',
  ProjectSettings: 'Project Settings',
};

export const navigationMenu = [
  { name: 'Approve Organizations', route: '/' },
  { name: 'Approve Carbon Project', route: '/carbon-project' },
  { name: 'Register Validators', route: '/validators' },
  { name: 'Protocol Info', route: '/protocol-info' },
];

export const organizationsTabs = {
  waitingForApprove: 'Waiting for approval',
  approved: 'Approved',
  rejected: 'Rejected',
};

export const orgListTypes = {
  approve: 'approve',
  reject: 'reject',
};

export const ValidationConstants = {
  NEAR_ACCOUNT_ID: /^(([a-z\d]+[-_])*[a-z\d]+\.)*([a-z\d]+[-_])*[a-z\d]+\.(testnet|near)$/gm,
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  SETTINGS_FIELD: /^[0-9]*$/,
};

export const SettingsFieldTypes = {
  ha: 'ha',
  amount: 'amount',
};

export const refreshTokenTimeout = 600000;

export const storageKeys = {
  token: 'token',
  comment: 'comment',
  currentNearId: 'currentNearId',
  toCreateValidatorData: 'toCreateValidatorData',
  isSignIn: 'isSignIn',
  theme: 'theme',
};

export const depositValues = {
  one: '1',
  second: '1',
};

export const permissionsModalTypes = {
  registration: 'registration',
  changing: 'changing',
  removing: 'removing',
};
