import React from 'react';

const stepsValues = [
  { text: 'General information', key: 'General information' },
  { text: 'Project activity ', key: 'Project activity' },
  { text: 'Land information', key: 'Land information' },
  { text: 'Legal and financial', key: 'Legal and financial' },
  { text: 'Risks', key: 'Risks' },
  { text: 'Documents & Contacts', key: 'Contact information' },

];
const StepsTabs = ({ step, setStep }) => (
  <div className="eval__steps-wrapper">
    {stepsValues.map((elem, index) => <div className={`eval__step ${step === index + 1 ? 'active' : ''}`} key={elem.key} onClick={() => setStep(index + 1)}>{elem.text}</div>)}
  </div>
);

export default StepsTabs;
